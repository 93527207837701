import StoryChief from '@/storychief';
import { Review } from '@/types/review';

/*
 * Detect if the current logged-in user or identified (public pages) has a pending review.
 */
export default function currentUserHasPendingReview(model?: { reviews: Review[] }): boolean {
  const user = StoryChief.user;

  if (!model || !user) {
    return false;
  }

  return model?.reviews
    ?.filter((review) => !review.accepted_at && !review.declined_at)
    ?.some((review) => {
      if (user.id && user.role) {
        return user.id.toString() === review.reviewer_id;
      }

      return user.email === review.reviewer_email;
    });
}
