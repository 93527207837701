import PropTypes from 'prop-types';
import useComments from '@/comments/hooks/useComments';

const propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
const defaultProps = {
  className: null,
  onClick: () => {},
};

function CommentsButton({ ...buttonProps }) {
  // Hooks
  const { comments } = useComments('cache-only');

  // Variables
  const unresolvedComments = getUnresolvedComments();

  // Functions
  function getUnresolvedComments() {
    return comments.filter((c) => !c.resolved && !c.deleted_at);
  }

  // Rendering
  return (
    <button
      type="button"
      data-testid="primary-comments-button"
      data-intercom-target="primary-comments-button"
      {...buttonProps}
    >
      <span>Comments</span>
      {unresolvedComments.length > 0 && (
        <span>
          <span>&nbsp;</span>
          <span>({unresolvedComments.length})</span>
        </span>
      )}
    </button>
  );
}

CommentsButton.propTypes = propTypes;
CommentsButton.defaultProps = defaultProps;

export default CommentsButton;
